import React from "react"
import { BreadCrumbs, SectionHeader, Page, WhereNext, Figure } from "../../components/page"
import * as theme from "../../theme"
import headerImage from "./cathedral-header.svg"
import Layout from "../../layout"
import pulpit from "./pulpit.svg"
import becket from "./becket2.svg"
import lecturn from './lecturn.svg';
import { CopyrightBox, DefinitionBox, DiscussBox } from '../../components/boxes'
import { StaticImage } from 'gatsby-plugin-image'

const TheCathedral = () => (
  <Layout title="The Cathedral">
    <SectionHeader color={theme.colors.blue.mid} image={headerImage}>
      The Cathedral
    </SectionHeader>
    <BreadCrumbs items={[
      { name: 'The Cathedral' }
    ]} />
    <Page>
      <h1>Canterbury Cathedral - A Sacred Space</h1>
      <p className="page-left">
        Canterbury Cathedral is a holy place and part of a World Heritage Site. It is the mother church of the worldwide Anglican Communion and the seat of the Archbishop of Canterbury. Our story began in the year 597 when a group of monks led by St Augustine arrived in Kent. They had been sent by the pope in Rome on a special mission to spread Christianity across England. Augustine based himself in Canterbury and set down his seat to become the first Archbishop of Canterbury.
      </p>
      <Figure
        className="page-right row-2"
        caption="Aerial view of Canterbury Cathedral."
      >
        <StaticImage
          src="./cathedral.jpg"
          alt="Canterbury Cathedral Aerial View"
        />
      </Figure>
      <p className="page-left">
        For over 1400 years people have come to the Cathedral to pray and gather for important Christian events. Everything within the Cathedral from the magnificent architecture to the beautiful stained glass is designed to help people experience the presence of God.
      </p>
      <hr />
      <h2>Nave</h2>
      <p className="page-left">
        The main body of the Cathedral is called the Nave. The Nave is a place where people have gathered together throughout the ages. Hundreds of people pass through here daily to pray, visit or take part in services and events such as Remembrance, graduation and concerts.
      </p>
      <Figure
        caption="View of Nave looking towards the Great West Window."
        className="page-right row-3"
      >
        <StaticImage
          src="./nave-inside.jpg"
          alt="The inside of the Nave"
        />
      </Figure>
      <p className="page-left">
        The Compass Rose represents the Mother Church and points to the four corners of the world to remind us of the wider Anglican family.
      </p>

      <p className="page-left">
        The pulpit is where teaching through talks (called sermons) is given. It is tall so that everyone in the Nave can see and hear the person speaking.
      </p>
      <img src={pulpit} alt="Illustration of a pulpit." className="page-left image-small" />
      <Figure
        caption="The Compass Rose is set into the floor of the nave, shown here in the foreground with the Nave Altar and pulpitum steps behind."
        className="page-right"
      >
        <StaticImage
          src="./compass-rose.jpg"
          alt="The Compass Rose, inset into the floor of the Nave."
        />
      </Figure>

      <DiscussBox>
        Did you know there is 1200m<sup>2</sup> of stained glass in Canterbury Cathedral? That amount would cover over 4 tennis courts.
      </DiscussBox>
      <Figure
        caption="South West Window in the nave."
      >
        <StaticImage
          src="./stained-glass.jpg"
          alt="Stained Glass Windows"
        />
      </Figure>
      <p className="page-left">
        The oldest stained glass window in the Cathedral is in the Nave and is nearly 850 years old. It shows Adam digging.
      </p>
      <Figure
        caption="A close up of the Adam window."
        className="page-right row-2"
      >
        <StaticImage
          src={'./adam.jpg'}
          alt="Stained glass depiction of Adam."
        />
      </Figure>
      <DefinitionBox title="Nave" className="page-left">
        Central and principal part of a Christian church, extending from the entrance to the transepts/chancel.
      </DefinitionBox>

      <hr />

      <h2>Martyrdom</h2>
      <p className="page-left">
        The Martyrdom is the site where Archbishop Thomas Becket was murdered in December 1170 by four knights following his falling out with King Henry II.
      </p>
      <Figure
        caption="The Martyrdom with the altar below and above a sculpture representing the four swords for the four knights (two metal swords with reddened tips and their two shadows)."
        className="page-right row-3"
      >
        <StaticImage
          src="./martyrdom.jpg"
          alt="The Martyrdom, showing the altar that marks the place where Becket died."
        />
      </Figure>
      <p className="page-left">
        Becket’s murder made Canterbury the most important pilgrimage site in England.
      </p>
      <img
        className="page-left image-small"
        src={becket}
        alt="Thomas Becket"
      />

      <DiscussBox>
        Imagine you are a medieval pilgrim visiting the shrine of Saint Thomas Becket – how do you feel when you arrive at the Cathedral?
      </DiscussBox>

      <h2>Cloisters and Chapter House</h2>
      <p className="page-left">
        The Cathedral once had a busy monastery attached where the monks used to live, learn, work and pray. The monks lived their lives by a set of rules called the Rule of Saint Benedict.
      </p>
      <Figure
        caption="The Great Cloister"
        className="page-right row-2"
      >
        <StaticImage
          src="./cloister.jpg"
          alt="The cloister, looking from the edge, through the columns, across the grass covered centre."
        />
      </Figure>
      <StaticImage
        src="./monk.png"
        alt="Monk"
        className="page-left row-2 image-small"
      />
      <Figure
        caption="The Chapter House"
        className="page-right row-2"
      >
        <StaticImage
          src="./chapter-house.jpg"
          alt="The inside of the Chapter House, showing the stained glass windows and ornate ceiling."
        />
      </Figure>
      <p className="page-left">
        During this time the Great Cloister connected the different parts of the monastery. Every day the monks met in the Chapter House to read a chapter from the Rule of Saint Benedict.
      </p>

      <hr />

      <h2>Crypt</h2>
      <p className="page-left">
        The crypt is located underneath the Quire and Trinity Chapel. Today it is a quiet place reserved for prayer and refection. It contains the earliest surviving parts of the Cathedral – built just after the conquest of England by the Normans of northern France. In the Western Crypt there are sturdy rounded arches and round columns which are topped with beautiful carvings. Originally everything would have been decorated in colourful paint.
      </p>
      <Figure
        caption="The Western Crypt looking towards Our Lady Undercroft."
        className="page-right row-2"
      >
        <StaticImage
          src="./crypt.jpg"
          alt="View inside the western crypt, looking toward the altar."
        />
      </Figure>
      <p className="page-left">
        The Eastern Crypt was the site of Becket’s tomb. His body was here for 50 years before it was translated (moved) to a magnificent shrine in the Trinity Chapel in 1220.
      </p>

      <hr />

      <h2>Quire</h2>
      <p>
        The Quire is the heart of the Cathedral where many services used to take place, including daily evensong and the main Sunday worship. The benches either side are called the ‘Quire stalls’ and the Cathedral choir sing here. This part of the Cathedral was rebuilt after a catastrophic fire in 1174. Choirs have been singing in this location since the Cathedral was built, as this was the place reserved for the monks to sing worship.
      </p>
      <p className="page-left">
        In the middle of the Quire is a lectern in the shape of an eagle. The eagle is the symbol of St John the Evangelist and the Bible is read aloud here during services.
      </p>

      <StaticImage
        src="./quire.jpg"
        alt="View of the Quire, looking towards St. Augustine's Chair and the Trinity Chapel beyond."
        className="page-right row-2"
      />
      <img src={lecturn} alt="Lecturn" className="page-left row-2 image-small" />
      <Figure
        caption="Saint Augustine’s Chair."
        className="page-right row-2"
      >
        <StaticImage
          src="./st-augustines-chair.jpg"
          alt="St. Augustine's Chair"
        />
      </Figure>
      <p className="page-left">
        The high altar is the main Cathedral altar and the chair at the top of the steps is St Augustine’s chair – the seat or ‘cathedra’ of the Archbishop of Canterbury.
      </p>

      <DiscussBox>
        The Eagle on the lectern is the symbol of St John the Evangelist. Why do you think the Eagle is used to represent St John?
      </DiscussBox>

      <h2>Trinity Chapel</h2>
      <p className="page-left">
        The Trinity Chapel is the highest part of the Cathedral visitors can access and was built to house the shrine of Archbishop Thomas Becket.
      </p>
      <Figure
        caption="The Trinity Chapel with the candle on the floor, marking the place where the shrine of St Thomas Becket once stood."
        className="page-right row-2"
      >
        <StaticImage
          src="./trinity.jpg"
          alt="Inside the Trinity Chapel"
        />
      </Figure>
      <p className="page-left">
        The stained glass windows around the outside of the chapel are called the ‘Miracle Windows’ because they tell the stories of some of the miracles said to have happened shortly after the death of Saint Thomas in 1170.
      </p>

      <hr />

      <WhereNext
        items={[
          { name: "A Walk Through Time", to: "/a-walk-through-time" },
          { name: "Pilgrimage", to: "/pilgrimage" },
          { name: "How did they build that?", to: "/how-did-they-build-that" },
        ]}
      />

      <hr />

      <CopyrightBox>
        <p><strong>The following images are licenced under creative commons:</strong></p>
        <ul>
          <li>Canterbury Cathedral Aerial Image, courtesy of John Fielding</li>
          <li>The Nave of Canterbury Cathedral, courtesy of David Illif, Licence: CC BY-SA 3.0</li>
          <li>The Trinity Chapel, courtesy of Laurence OP</li>
        </ul>
      </CopyrightBox>

    </Page>
  </Layout>
)

export default TheCathedral;